import React from "react"
// import AccountLayout from "../../../components/accountLayout"
// // import BeneficialOwnerReview from "../../../components/beneficialOwnerReview"

const BeneficialOwnerReviewPage = () => {
  return (
    <>
    {/* <AccountLayout title="Add beneficial owners" to="/account-details/status">
      <form id="form-id">{typeof window === "undefined" && <BeneficialOwnerReview />}</form>
    </AccountLayout> */}
    </>
    
  )
}

export default BeneficialOwnerReviewPage
